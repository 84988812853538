import React from "react";

import b2 from "../../images/benefit/02.png";
import b3 from "../../images/benefit/03.png";
import b4 from "../../images/benefit/04.png";
import b5 from "../../images/benefit/05.png";
import b6 from "../../images/benefit/06.png";
import b7 from "../../images/benefit/07.png";
import b9 from "../../images/benefit/09.png";
import b10 from "../../images/benefit/10.png";
import b12 from "../../images/benefit/12.png";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import { colorPalette } from "../colors";
import { Typography } from "@material-ui/core";

const benefits = [
  {
    title: "Aylık Bonus",
    src: b9,
    desc:
      "Tüm Decathlonlu takım arkadaşları bulunduğu mağazanın veya ülkenin aylık performansına göre bonus hak eder. Pozisyona göre baz maaşın belli bir oranıyla hesaplanan bu bonusların miktarı yine pozisyona göre farklı parametreler üzerinden belirlenir.",
  },
  {
    title: "Yemek Kartı",
    src: b6,
    desc: "Decathlonlular birçok restoranda ve markette kullanabilecekleri Ticket Restaurant kartına sahiptir.",
  },
  {
    title: "Spor Desteği",
    src: b12,
    desc:
      "Her yıl, Decathlonluların deneyimlediği sporlara destek oluyoruz! Bu kapsamda; ilk iki ayını tamamlayan her Decathlonlu, yaptığı sporlar için spor desteğini talep edebilir.",
  },
  {
    title: "Decathlonlu Kart",
    src: b3,
    desc:
      "Decathlon’da ilk iki ayını tamamlayan tüm takım arkadaşlarımız, indirimli olmayan ürünlerde kullanmak üzere indirim sağlayan Decathlonlu Kart’ını almaya hak kazanır. Sadece tutku markalarımızda uygulanan bu indirim, ülke çapındaki tüm mağazalarımızda ve online satışlarımızda geçerlidir.",
  },
  {
    title: "Evlilik ve Doğum",
    src: b2,
    desc:
      "Decathlon, takım arkadaşlarının özel günlerinde ona destek olur. Evlilik ve yenidoğan minik Decathlonlular için her sene yeniden belirlenen seviyede bir ödeme, maaşına ek olarak hediye edilir.",
  },
  {
    title: "Hayat Sigortası",
    src: b5,
    desc:
      "Decathlonlular engellilik ve vefat durumlarında kendilerine veya ailelerine destek olacak hayat sigortasına sahiptir.",
  },
  {
    title: "Özel Sağlık Sigortası",
    src: b4,
    desc:
      "Decathlon’da sağlık giderlerin için birden çok seçenek var! İhtiyacına ve bütçene göre karar vererek, sana özel yaptığımız sigorta anlaşmalarından birini seçebilir, sağlığını teminat altına alabilirsin.",
  },
  {
    title: "Hissedarlık",
    src: b10,
    desc:
      "Decathlon bizim, Decathlon hepimizin! Tüm takım arkadaşlarımız Decathlon ailesinin gerçek bir üyesidir. Tam da bu nedenle tüm Decathlonlular, dilerlerse bu eşsiz şirketin ve heyecanlı gelişim yolculuğumuzun birer ortağı, yani hissedarı olabilir.",
  },
  {
    title: "Kar Paylaşımı",
    src: b7,
    desc:
      "Birlikte çalışma kültürünün eşsiz örneği olan Decathlon’da, finansal başarılarımızı da hep birlikte paylaşıyoruz! Çalıştığın rolden bağımsız olarak, bir takvim yılı boyunca birlikte çalıştıysak ve sonraki yılın faaliyet karının ödeneceği tarihte de hala birlikte çalışıyorsak o yıla ilişkin elde edilen faaliyet karından bonus almaya hak kazanırsın.",
  },
];

const styles = (theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    gridGap: "1.5vw",
    maxWidth: "80%",
    margin: "auto",
    marginBottom: "5vh",
    marginTop: "calc(8vh + 8vw)",
  },

  paper: {
    borderRadius: "4px",
    backgroundColor: "#fff",
    padding: "20px",
    cursor: "default",

    "& img": {
      height: 250,
      [theme.breakpoints.down("sm")]: {
        height: 200,
      },
      width: "100%",
      objectFit: "contain",
    },
  },

  desc: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },

  lightTooltip: {
    backgroundColor: colorPalette.orange,
    color: "#fff",
    fontSize: "1em",
    padding: "16px",
    textAlign: "justify",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
});

const Benefits = ({ classes }) => (
  <section className={classes.root} id="benefits">
    {benefits.map((benefit) => (
      <Benefit classes={classes} benefit={benefit} key={benefit.title} />
    ))}
  </section>
);

const Benefit = ({ classes, benefit }) => (
  <div className={classes.paper}>
    <Tooltip disableFocusListener interactive title={benefit.desc} classes={{ tooltip: classes.lightTooltip }}>
      <img src={benefit.src} className={classes.img} alt="Benefit logo" />
    </Tooltip>
    <Typography variant="body1" className={classes.desc}>
      {benefit.desc}
    </Typography>
  </div>
);

export default withStyles(styles)(Benefits);
