import React from "react";
import { withStyles } from "@material-ui/core";
import Benefits from "../components/home/Benefits";
import SubPageBase from "../components/SubPageBase";
import img from "../images/yanhaklar.png";

const styles = theme => ({
  root: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "40vh"
  },
  title: {
    margin: "auto"
  }
});

const BenefitsPage = ({ classes }) => (
  <SubPageBase title="YAN HAKLARIMIZ" img={img}>
    <Benefits />
  </SubPageBase>
);

export default withStyles(styles)(BenefitsPage);
